import { EncryptStorage } from 'encrypt-storage';

const { REACT_APP_SECRET_KEY } = process.env;
const SECRET_KEY: string = REACT_APP_SECRET_KEY || 'c3a5f0e963a6ac4320O490238kldjklfba6626f85bab4c3bb16cdba8c3627521c59ab24ef4f33400b3d4fb3199f584187707f534c511cd5f2ed3d53b5d66e1d2303';
export const encryptStorage = new EncryptStorage(SECRET_KEY, {
  prefix: '@InvizInstance',
  storageType: 'sessionStorage',
});

export const encryptLocalStorage = new EncryptStorage(SECRET_KEY, {
  prefix: '@InvizInstance',
  storageType: 'localStorage',
});
