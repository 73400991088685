import { title } from 'process';
import Swal, {SweetAlertResult, SweetAlertIcon, SweetAlertOptions} from 'sweetalert2';

export const CustomConfirm = (title?: string, text?: string, icon?: SweetAlertIcon, callback?: (result: SweetAlertResult) => any, useCancel: boolean = true) => {
    let defaultCallback = (result: SweetAlertResult) => {

    };
    if (callback) defaultCallback = callback;
    const defaultOption: SweetAlertOptions = {
        showCancelButton: useCancel,
        width: '410px',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
    };
    if (icon) defaultOption.icon = icon;
    if (title) defaultOption.title = title;
    if (text) defaultOption.html = `<div style="font-size:15px">${text}</div>`;

    Swal.fire(defaultOption).then(defaultCallback);
};
export const CustomConfirm2 = (title?: string, text?: string, icon?: SweetAlertIcon, callback?: (result: SweetAlertResult) => any, useCancel: boolean = true) => {
    let defaultCallback = (result: SweetAlertResult) => {

    };
    if (callback) defaultCallback = callback;
    const defaultOption: SweetAlertOptions = {
        showCancelButton: useCancel,
        width: '410px',
        input: 'text',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        customClass: {container: 'v_annotation_input'},
    };
    if (icon) defaultOption.icon = icon;
    if (title) defaultOption.title = title;
    if (text) defaultOption.html = `<div style="font-size:15px">${text}</div>`;
    Swal.fire(defaultOption).then(defaultCallback);
};

export const saveConfirm = (title?: string, warn?: string, saveList?:any[], callback?: (result: SweetAlertResult) => any, useCancel: boolean = true) => {
    let defaultCallback = (result: SweetAlertResult) => {

    };
    if (callback) defaultCallback = callback;
    const list = saveList && saveList.length > 0 ? saveList.map(item => `
        <tr>
          <td style="text-align: left; width:55px; max-width: 55px;">${item.rule_modality}</td>
          <td style="text-align: left; width: 75px; max-width: 75px; ">${item.rule_body_part}</td>
          <td style="text-align: left; width: 150px; max-width: 150px;">${item.rule_description}</td>
          <td style="text-align: right; width: 70px; max-width: 70px;">${item.rule_hospital_price.toLocaleString()}</td>
          <td style="text-align: right; width: 70px; max-width: 70px;">${item.rule_hospital_er.toLocaleString()}</td>
          <td style="text-align: right; width: 70px; max-width: 70px;">${item.rule_doctor_price.toLocaleString()}</td>
          <td style="text-align: right; width: 70px; max-width: 70px;">${item.rule_doctor_er.toLocaleString()}</td>
        </tr>
      `).join(''):'';
    const defaultOption: SweetAlertOptions = {
        showCancelButton: useCancel,
        width: '600px',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        html: `
        <div style="display: flex;justify-content: center;">
          <div style="margin-top: 15px; display: flex; flex-direction: column;align-items: flex-start;">
            <span style="font-size: 12px">${warn}</span>
            <div class="table_type2">
                <table style="border-collapse: collapse;width: 100%;">
                    <thead style="font-size:12px; display:block; border-bottom:1px solid #eee">
                        <th style="padding: 4px 8px; width: 55px">장비</th>
                        <th style="padding: 4px 8px; width: 75px">검사부위</th>
                        <th style="padding: 4px 8px; width: 150px">검사명</th>
                        <th style="padding: 4px 8px; width: 70px">판독료(병원)</th>
                        <th style="padding: 4px 8px; width: 70px">응급배율(병원)</th>
                        <th style="padding: 4px 8px; width: 70px">판독료(판독의)</th>
                        <th style="padding: 4px 8px; width: 70px">응급배율(판독의)</th>
                    </thead>
                    <tbody style="height: 200px;overflow-y: scroll; display:block; width: 100%">
                        ${list}
                    </tbody>
                </table>
            </div> 
          </div>
        </div>
      `,
    };
    if (title) defaultOption.title = title;
    // if (text) defaultOption.html = `<div style="font-size:15px">${text}</div>`;
    Swal.fire(defaultOption).then(defaultCallback);
};
