import { setHeaderToken } from '@lib/axios';
import { createPersistStore } from '../store';
import { SelectorHook } from '../type';
import {UserInitialState} from "@UserTypes/UserTypes";


const useUserStore = createPersistStore<UserInitialState>((set) => ({
  userData: null,
  token: null,
  refreshToken: null,
  actions: {
    setUserData: (userData) => { set((state) => { state.userData = userData; }); },
    setToken: (token) => { set((state) => { state.token = token; }); },
    setRefreshToken: (refreshToken) => { set((state) => { state.refreshToken = refreshToken; }); },
  },
}), 'userDataStorage');

useUserStore.subscribe((state) => state.token, (token) => {
  setHeaderToken(token||'');
  /*if (userData) {

  }*/
});
export const useUserActions = () => useUserStore((state) => state.actions);
export const getUserData = () => useUserStore.getState();
export const useUserData: SelectorHook<UserInitialState, 'userData'> = (selector = (state: UserInitialState) => state.userData) => useUserStore(selector);
