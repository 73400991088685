import React, { ReactNode } from 'react'
import "@assets/css/manage/center.css";
import { Outlet } from 'react-router-dom';

const ManageLayout= () => {
    return (
      <div className="manage-layout">
        <Outlet />
      </div>
    );
  };

export default ManageLayout
