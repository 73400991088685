import {FaBuilding, FaCog, FaCogs, FaCreditCard, FaList, FaNetworkWired, FaHospital } from "react-icons/fa";
import {IconType} from "react-icons";
export type menuListType = {
    id: string
    url: string
    icon: IconType
    name: string
    disabled: boolean
    children?: any[]
}
export const menuList:menuListType[] = [
    {id: 'reading', url: '/reading', icon: FaList, name: '의뢰검사조회', disabled: false},
    {id: 'payment', url: '/payment/hospital', icon: FaCreditCard, name: '정산조회', disabled: false,
        children:[
            {id: 'hospital', url: '/payment/hospital', icon: FaBuilding, name: '병원 정산', disabled: false},
            {id: 'doctor', url: '/payment/doctor', icon: FaNetworkWired , name: '판독의 정산', disabled: false},
        ]
    },
    {id: 'manage', url: '/manage/pay', icon: FaCog  , name: '관리', disabled: false,
        children: [
           /* {id: 'center', url: '/manage/center', icon: FaBuilding, name: '센터 관리', disabled: false},
            {id: 'adminhospital', url: '/manage/hospital', icon: FaHospital  , name: '병원 관리', disabled: false},*/
            // {id: 'adminUser', url: '/manage/admin', icon: FaNetworkWired , name: '관리자 관리', disabled: false},
            // {id: 'user', url: '/manage/user', icon: FaList, name: '사용자 관리', disabled: false},
            {id: 'pay', url: '/manage/pay', icon: FaList, name: '판독료 관리', disabled: false}
        ]
    },
    // {id: 'viewer', url: '/viewer', icon: FaCreditCard, name: '뷰어테스트', disabled: false},
]