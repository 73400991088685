import {Toast} from "@lib/Toas";

export const logOnDev = (...data: any[]):void => {
    if (process.env.NODE_ENV !== 'production') {
        window.console.log(data);
    }
};

export const onError = async (message: string) => {
    Toast(message, 'error');
    return Promise.resolve();
};
