import React, {lazy, Suspense} from 'react';
import { Route, Routes } from "react-router-dom";
import '@hoc/Auth'
import '@assets/css/common/default.css'
import '@assets/css/common/common.css'
import Login from "@pages/user/Login";
import Auth from "@hoc/Auth";
import Layout from "@layout/Layout";
import ManageLayout from '@pages/manage/ManageLayout';

function App() {
    const LoginPage = Auth(Login, false, false)
    const LayoutPage = Auth(Layout, true, false)
    const ReadingPage = lazy(() => import("@component/reading/ReadingList"));
    const PayHosPage = lazy(() => import("@component/pay/PayHospitalList"));
    const PayDocPage = lazy(() => import("@component/pay/PayDoctorList"));
    const NotFoundPage = lazy(() => import("@pages/error/NotFound"));
    const CenterPage = lazy(() => import("@pages/manage/Center"));
    const ManagePayPage = lazy(() => import("@component/manage/PayManage"));
    const ManageCenterPage = lazy(() => import("@component/manage/CenterManage"));
    const ManageHospitalPage = lazy(() => import("@component/manage/HospitalManage"));

  return (
   <Suspense fallback={null}>
     <Routes>
         <Route path='/' element={<LayoutPage/>}>
             <Route index element={<ReadingPage/>}/>
             <Route path='reading' element={<ReadingPage/>}/>
             <Route path='payment'>
                <Route path='hospital' element={<PayHosPage/>}/>
                <Route path='doctor' element={<PayDocPage/>}/>
             </Route>
             <Route path='manage' element={<ManageLayout/>}>
                 <Route path='user'/>
                 <Route path='hospital' element={<ManageHospitalPage/>}/>
                 <Route path='center' element={<ManageCenterPage/>}/>
                 <Route path='pay' element={<ManagePayPage/>}/>
             </Route>
         </Route>
         <Route path="/login" element={<LoginPage/>} />
         <Route path="*" element={<NotFoundPage/>}/>
     </Routes>
   </Suspense>
  );
}

export default App;
