import axios from 'axios';
import { logOnDev, onError } from '@lib/function';
import { getUserData} from '@store/user/store';
import {encryptStorage} from "@lib/encryptStorage";

const BASE_URL = process.env.REACT_APP_API_URL;

const client = axios.create({
    baseURL: `${BASE_URL}/api`,
    withCredentials: false,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
    },
});

export const setHeaderToken = (token: string) => {
    client.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeHeaderToken = () => {
    delete client.defaults.headers.common.Authorization;
};

const postRefreshToken = () => client.post('/user/auth/refresh', { refresh_token: 'refresh_token' });

/**
 * 실행 전처리 토큰 검사 및 get parameter 형식 변경
 * @param config axios 옵션
 */
client.interceptors.request.use(request=> {
    const token = encryptStorage.getItem('token');
    if (token) {
        setHeaderToken(token);
        request.headers['Authorization'] = `Bearer ${token}`;
    }
    if (request.method === 'get') {
        request.params = { ...request.params, _t: Date.now() };
        request.paramsSerializer = {
            serialize: (params) => new URLSearchParams(Object.entries(params).flatMap(([key, value]) =>
                Array.isArray(value) ? value.map(v => [key, v]) : [[key, value]]
            )).toString()
        };
    }
    return Promise.resolve(request);
}, error => {
    return Promise.reject(error);
})

client.interceptors.response.use(response=> {
    const { method, url } = response.config;
    const { status } = response;
    logOnDev(`👻👻👻 [API] ${method?.toUpperCase()} ${url} | Response ${status}`);
    return Promise.resolve(response);
},async error => {
    if (axios.isAxiosError(error)) {
        const { message, config, response } = error;
        const { method, url } = config || {};
        const { status, statusText } = response || {};

        if (status === 401) {
            try {
                const tokenResponse = await postRefreshToken();
                if (tokenResponse.status === 201) {
                    console.log('Token refreshed successfully');
                }
            } catch (refreshError) {
                if (axios.isAxiosError(refreshError) &&
                    (refreshError.response?.status === 404 || refreshError.response?.status === 402)) {
                    const actions = getUserData().actions;
                    actions.setUserData(null);
                    window.location.href = '/login';
                }
            }
        }
        logOnDev(`🚧🚧🚧 [API] ${method?.toUpperCase()} ${url} | Error ${status} ${statusText} | ${message}`);
    } else {
        logOnDev(`🚧🚧🚧 [API] Error ${error.message}`);
        await onError(error.message);
    }
    return Promise.reject(error);
})
export default client;
