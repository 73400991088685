import client from '@lib/axios';
import {LoginFrom, LogoutResponse, UserResponse} from "@UserTypes/UserTypes";

export default class UserService {
    /**
     * 사용자 로그인 처리
     * @param data {user_id: string, user_passwd: string, user_overwrite: boolean}
     */
    public static async login(data:LoginFrom):Promise<UserResponse> {
        return await client.post('/user/auth/login', data)
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));
    }

    public static async logout():Promise<LogoutResponse> {
        return await client.get('/user/auth/logout')
            .then((response) => response.data)
            .catch((error) => Promise.reject(error.response ? error.response.data : error.message));

    }
}
